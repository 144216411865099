import { getProject } from 'common/api/v1/contactProject';

import selectProject from '../../projectConsumptionCard/actions/selectProject';
import selectProjectModal from '../../projectConsumptionsModal/actions/selectProject';
import {
    FETCH_PROJECT_DETAILS_FAILURE,
    FETCH_PROJECT_DETAILS_SUCCESS,
    FETCH_PROJECT_DETAILS,
} from '../actionTypes';
import { projectActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(projectActions[FETCH_PROJECT_DETAILS]());

    getProject(id)
        .then((response) => {
            dispatch(
                projectActions[FETCH_PROJECT_DETAILS_SUCCESS](response.data)
            );
            dispatch(selectProject(response.data));
            dispatch(selectProjectModal(response.data));
        })
        .catch((error) =>
            dispatch(projectActions[FETCH_PROJECT_DETAILS_FAILURE](error))
        );
};
