import i18next from 'i18next';
import { get, isEmpty } from 'lodash';
import { createSelector } from 'reselect';

import orm from 'common/orm';
import { arraytoDictionary } from 'common/utils/helpers';
import { getIsMexicanAccount } from 'common/utils/helpers/session';

import { CONSUMPTIONS_CAPTURE_MODE, NAME } from './constants';

export const getModel = (state) => {
    return state[NAME];
};

export const getEntitiesSession = createSelector(getModel, (state) =>
    orm.session(state.entities)
);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getModalIsOpen = createSelector(
    getModel,
    (model) => model.modalIsOpen
);

export const getIsFromCreate = createSelector(
    getModel,
    (model) => model.isFromCreate
);

export const getAutomaticHasChanges = createSelector(
    getModel,
    (model) => model.automaticHasChanges
);

export const getSelectedProject = createSelector(
    getModel,
    (model) => model.selectedProject
);

/* Tarifas */

export const getFetchRates = createSelector(
    getModel,
    (model) => model.fetchRates
);

export const getIsFetchingRates = createSelector(
    getFetchRates,
    (fetchRates) => fetchRates.isFetching
);

export const getRatesData = createSelector(getEntitiesSession, ({ Rate }) =>
    Rate.all().toRefArray()
);

export const getRatesDictionary = createSelector(getRatesData, (rates) =>
    rates.reduce((acc, current) => {
        if (!current?.id) return acc;
        acc[current.id] = current;
        return acc;
    }, {})
);

export const getRatesNameIdDictionary = createSelector(getRatesData, (rates) =>
    rates.reduce((acc, current) => {
        if (!current?.name) return acc;
        acc[current.name] = current.id;
        return acc;
    }, {})
);

export const getCertifiedRatesNameIdDictionary = createSelector(
    getRatesData,
    (rates) =>
        rates.reduce((acc, current) => {
            if (current?.isCertified) {
                acc[current.name] = current.id;
            }
            return acc;
        }, {})
);

export const getRatesNameDictionary = createSelector(getRatesData, (rates) =>
    rates.reduce((acc, current) => {
        if (current?.isEnabled) {
            acc[current.name] = current;
        }
        return acc;
    }, {})
);

export const getGroupedRatesForSelect = createSelector(
    getRatesData,
    getInitialValues,
    (rates, initialValues) =>
        rates.reduce((acc, rate) => {
            if (!rate.isEnabled && rate.id !== initialValues.rate) return acc;

            const brandName = rate.isCertified
                ? i18next.t('Certificated')
                : i18next.t('Custom', { context: 'female', count: 2 });

            let rateName = rate.name || '';
            if (!rate.isEnabled) rateName += ` (${i18next.t('Archived')})`;
            const option = { label: rateName, value: rate.id };

            if (!acc[brandName])
                acc[brandName] = { label: brandName, options: [] };

            acc[brandName].options.push(option);

            return acc;
        }, {})
);

export const getLocationsByZipCode = createSelector(
    getModel,
    (model) => model.locationsByZipCode
);

export const getIsFetchingLocationsByZipCode = createSelector(
    getLocationsByZipCode,
    (data) => data.isFetching
);

export const getLocationsByZipCodeData = createSelector(
    getLocationsByZipCode,
    (data) => {
        const isMexicanAccount = getIsMexicanAccount();
        if (isEmpty(data.data)) return [];
        return [
            {
                label: `${
                    isMexicanAccount
                        ? i18next.t('City')
                        : i18next.t('Political division of order 2')
                } *`,
                value: '',
            },
            ...data.data.map((location) => ({
                label: get(location, 'municipality.name', ''),
                value: get(location, 'municipality.id', ''),
            })),
        ];
    }
);

/* Divisiones */
export const getDivisions = createSelector(
    getModel,
    (model) => model.divisions
);

export const getIsFetchingDivisions = createSelector(
    getDivisions,
    (divisions) => divisions.isFetching
);

export const getDivisionsData = createSelector(
    getDivisions,
    (data) => data.data || []
);

export const getDivisionsForSelect = createSelector(
    getDivisionsData,
    (dataValues) => [
        {
            label: i18next.t('Division').concat(' *'),
            value: '',
        },
        ...dataValues.map((value) => ({
            label: value.name,
            value: value.id,
        })),
    ]
);

/* Regiones */
export const getRegions = createSelector(getModel, (model) => model.regions);

export const getIsFetchingRegions = createSelector(
    getRegions,
    (data) => data.isFetching
);

export const getRegionsData = createSelector(
    getRegions,
    (data) => data.data || []
);

export const getRegionsForSelect = createSelector(
    getRegionsData,
    (dataValues) => [
        {
            label: i18next.t('Region').concat(' *'),
            value: '',
        },
        ...dataValues.map((value) => ({
            label: value.name,
            value: value.id,
        })),
    ]
);

/* Meses de verano */
export const getSummerMonths = createSelector(
    getModel,
    (model) => model.summerMonths
);

export const getIsFetchingSummerMonths = createSelector(
    getSummerMonths,
    (data) => data.isFetching
);

export const getSummerMonthsData = createSelector(
    getSummerMonths,
    (data) => data.data || []
);

export const getMonthsForSelect = createSelector(
    getSummerMonthsData,
    (dataValues) => [
        {
            label: i18next.t('Start of summer').concat(' *'),
            value: '',
        },
        ...dataValues.map((value) => ({
            label: value.name,
            value: value.id,
        })),
    ]
);

/* Divisiones Políticas */
export const getPoliticalDivisions = createSelector(
    getModel,
    (model) => model.politicalDivisions
);

export const getIsFetchingPoliticalDivisions = createSelector(
    getPoliticalDivisions,
    (data) => data.isFetching
);

export const getPoliticalDivisionsData = createSelector(
    getPoliticalDivisions,
    (data) => data.data || []
);

export const getPoliticalDivisionsDictionary = createSelector(
    getPoliticalDivisionsData,
    (data) => arraytoDictionary(data, 'id') || {}
);

export const getPoliticalDivisionsForSelect = createSelector(
    getPoliticalDivisionsData,
    (dataValues) => {
        const isMexicanAccount = getIsMexicanAccount();
        return [
            {
                label: `${
                    isMexicanAccount
                        ? i18next.t('State')
                        : i18next.t('Political division of order 1')
                } *`,
                value: '',
            },
            ...dataValues.map((value) => ({
                label: value.name,
                value: value.id,
            })),
        ];
    }
);

/* Divisiones Políticas 2*/

export const getPoliticalDivisions2 = createSelector(
    getModel,
    (model) => model.politicalDivisions2
);

export const getIsFetchingPoliticalDivisions2 = createSelector(
    getPoliticalDivisions2,
    (data) => data.isFetching
);

export const getPoliticalDivisions2Data = createSelector(
    getPoliticalDivisions2,
    (data) => data.data || []
);

export const getPoliticalDivisions2Dictionary = createSelector(
    getPoliticalDivisions2Data,
    (data) => arraytoDictionary(data, 'id') || {}
);

export const getPoliticalDivisions2ForSelect = createSelector(
    getPoliticalDivisions2Data,
    (dataValues) => {
        const isMexicanAccount = getIsMexicanAccount();
        return [
            {
                label: `${
                    isMexicanAccount
                        ? i18next.t('City')
                        : i18next.t('Political division of order 2')
                } *`,
                value: '',
            },
            ...dataValues.map((value) => ({
                label: value.name,
                value: value.id,
            })),
        ];
    }
);

/** Upload PDF File */
export const getUploadPDFFile = createSelector(
    getModel,
    (model) => model.uploadPDFFile
);

export const getUploadPDFFileData = createSelector(
    getUploadPDFFile,
    (data) => data.data
);

export const getIsUploadingPDFFile = createSelector(
    getUploadPDFFile,
    (data) => data.isUploading
);

export const getUploadPDFFileErrors = createSelector(
    getUploadPDFFile,
    (data) => data.errors
);

export const getSaveConsumptions = createSelector(
    getModel,
    (model) => model.saveConsumptions
);

export const getIsSavingSaveConsumptions = createSelector(
    getSaveConsumptions,
    (saveConsumptions) => saveConsumptions.isSaving
);

export const getErrorsSaveConsumptions = createSelector(
    getSaveConsumptions,
    (saveConsumptions) => saveConsumptions.errors
);

export const getProfileConsumptionModel = createSelector(
    getModel,
    (model) => model.profileConsumption
);

export const getProfileConsumptionData = createSelector(
    getProfileConsumptionModel,
    (model) => model.data
);

export const getUploadConsumptionProfileModel = createSelector(
    getModel,
    (model) => model.uploadConsumptionProfile
);

export const getUploadConsumptionProfileErrors = createSelector(
    getUploadConsumptionProfileModel,
    (model) => model.errors
);

export const getUploadConsumptionProfileIsUploading = createSelector(
    getUploadConsumptionProfileModel,
    (model) => model.isUploading
);

/** cosumption profiles */
export const getFetchConsumptionProfilesModel = createSelector(
    getModel,
    (model) => model.fetchConsumptionProfiles
);

export const getFetchConsumptionProfilesData = createSelector(
    getFetchConsumptionProfilesModel,
    (model) => model.data || []
);

export const getFetchConsumptionProfilesErrors = createSelector(
    getFetchConsumptionProfilesModel,
    (model) => model.errors
);

export const getFetchConsumptionProfilesIsFetchhing = createSelector(
    getFetchConsumptionProfilesModel,
    (model) => model.isFetching
);

export const getGroupedConsumptionProfilesForSelect = createSelector(
    getFetchConsumptionProfilesData,
    (data) =>
        data?.reduce((acc, curr) => {
            const brandName =
                curr.type === 0
                    ? i18next.t('Certificated', { context: 'male' })
                    : i18next.t('Custom', { count: 2 });
            const option = {
                label: curr.type === 0 ? i18next.t(curr.name) : curr.name,
                value: curr.id,
            };
            if (!acc[brandName])
                acc[brandName] = { label: brandName, options: [] };

            acc[brandName].options.push(option);

            return acc;
        }, {})
);

/** cosumption profile */
export const getFetchConsumptionProfileModel = createSelector(
    getModel,
    (model) => model.fetchConsumptionProfile
);

export const getFetchConsumptionProfileData = createSelector(
    getFetchConsumptionProfileModel,
    (model) => model.data
);

export const getFetchConsumptionProfileErrors = createSelector(
    getFetchConsumptionProfileModel,
    (model) => model.errors
);

export const getFetchConsumptionProfileIsFetchhing = createSelector(
    getFetchConsumptionProfileModel,
    (model) => model.isFetching
);

export const getHasMoreDaysThanAllowed = createSelector(
    getModel,
    (model) => model.hasMoreDaysThanAllowed
);

export const getConsumptionProfileCsv = createSelector(
    getModel,
    (model) => model.consumptionProfileCsv
);

export const getConsumptionProfileArray = createSelector(
    getConsumptionProfileCsv,
    (model) => model.consumptionProfileArray
);

export const getConsumptionProfileCsvData = createSelector(
    getConsumptionProfileCsv,
    (model) => model.data
);

export const getConsumptionWithCsvDataCalculated = createSelector(
    getModel,
    (model) => model.consumptionWithCsvDataCalculated
);

/** schedule rate distribution */

export const getFetchScheduleRateConfiguration = createSelector(
    getModel,
    (model) => model.fetchScheduleRateConfiguration
);

export const getFetchScheduleRateConfigurationData = createSelector(
    getFetchScheduleRateConfiguration,
    (fetchScheduleRateConfiguration) => fetchScheduleRateConfiguration.data
);

export const getFetchScheduleRateConfigurationErrors = createSelector(
    getFetchScheduleRateConfiguration,
    (fetchScheduleRateConfiguration) => fetchScheduleRateConfiguration.errors
);

export const getFetchScheduleRateConfigurationIsFetching = createSelector(
    getFetchScheduleRateConfiguration,
    (fetchScheduleRateConfiguration) =>
        fetchScheduleRateConfiguration.isFetching
);

export const getIsDisabled = createSelector(
    getModel,
    (model) => model.isDisabled
);

/** LISA */

export const getUploadFileOrigin = createSelector(
    getModel,
    (model) => model.uploadFileOrigin
);

export const getIsOpenMissingFieldsModal = createSelector(
    getModel,
    (model) => model.isOpenMissingFieldsModal
);

export const getConsumptionsRawData = createSelector(
    getModel,
    (model) => model.consumptionsRawData
);

export const getConsumptionsCaptureMode = createSelector(
    getModel,
    (model) => model.consumptionsCaptureMode
);

export const getIsFromCsv = createSelector(
    getConsumptionsCaptureMode,
    (mode) => mode === CONSUMPTIONS_CAPTURE_MODE.CSV
);

export const getIsFromScrapper = createSelector(
    getConsumptionsCaptureMode,
    (mode) => mode === CONSUMPTIONS_CAPTURE_MODE.SCRAPPER
);

export const getIsManually = createSelector(
    getConsumptionsCaptureMode,
    (mode) => mode === CONSUMPTIONS_CAPTURE_MODE.MANUAL
);

export const getFetchCupsInfo = createSelector(
    getModel,
    (model) => model.fetchCupsInfo
);

export const getIsFetchingCupsInfo = createSelector(
    getFetchCupsInfo,
    (fetchCupsInfo) => fetchCupsInfo.isFetching
);

export const getCupsInfoData = createSelector(
    getFetchCupsInfo,
    (fetchCupsInfo) => fetchCupsInfo.data
);

export const getHasCupsInfoData = createSelector(
    getCupsInfoData,
    (data) => !isEmpty(data)
);

/** CSV IMPORTER */
export const getConfigCsvImportModal = createSelector(
    getModel,
    (model) => model.csvImportModal
);

/** ZAP */
export const getAddZapRpu = createSelector(
    getModel,
    (model) => model.addZapRpu
);

export const getAddZapRpuData = createSelector(
    getAddZapRpu,
    (model) => model.data
);

export const getIsAddingZapRpu = createSelector(
    getAddZapRpu,
    (model) => model.isLoading
);

export const getFetchZapData = createSelector(
    getModel,
    (model) => model.fetchZapData
);

export const getFetchZapDataData = createSelector(
    getFetchZapData,
    (model) => model.data
);

export const getHasZapData = createSelector(
    getFetchZapDataData,
    (data) => data?.length > 0
);

export const getIsFetchingZapData = createSelector(
    getFetchZapData,
    (model) => model.isFetching
);

export const getIsLoadingZapIntegration = createSelector(
    getModel,
    (model) => model.isZapLoading
);

export const getFetchZapRpus = createSelector(
    getModel,
    (model) => model.fetchZapRpus
);

export const getFetchZapRpusData = createSelector(
    getFetchZapRpus,
    (model) => model.data || []
);

export const getIsFetchingZapRpus = createSelector(
    getFetchZapRpus,
    (model) => model.isFetching
);
